import React from "react";

function ClosedEyeIcon({ width, height, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "30"}
      height={height ? height : "26"}
      onClick={onClick}
      fill="none"
      viewBox="0 0 35 26"
      className="clickeable"
    >
      <path
        fill="#fff"
        d="M33.783 12.73c-.192-.26-3.108-4.194-7.53-7.099l-1.634 1.635c3.287 2.067 5.79 4.865 6.833 6.121-1.74 2.127-7.63 8.607-14.45 8.607-1.998 0-3.924-.558-5.684-1.402l-1.673 1.675c2.211 1.163 4.701 1.974 7.364 1.974 9.244 0 16.482-9.77 16.78-10.182.286-.4.286-.931-.006-1.33z"
      ></path>
      <path
        fill="#fff"
        d="M17.149 17.88c-.89 0-1.72-.26-2.418-.711l-1.607 1.608a6.678 6.678 0 004.031 1.343c3.706 0 6.727-3.018 6.727-6.733a6.72 6.72 0 00-1.341-4.034l-1.62 1.622a4.482 4.482 0 01-3.772 6.905zM29.28.309a1.066 1.066 0 00-1.5 0l-3.945 3.948c-2.079-1.017-4.383-1.702-6.833-1.702C7.758 2.55.52 12.32.214 12.731c-.285.392-.285.93 0 1.322.186.253 2.895 3.915 7.053 6.773l-3.347 3.35a1.068 1.068 0 000 1.502 1.061 1.061 0 001.5 0L29.28 1.81a1.056 1.056 0 000-1.502zM2.552 13.395c1.74-2.12 7.623-8.6 14.45-8.6 1.8 0 3.526.452 5.14 1.157l-1.62 1.621a6.667 6.667 0 00-3.368-.91c-3.705 0-6.727 3.017-6.727 6.732 0 1.23.332 2.38.91 3.37L8.9 19.205c-3.055-2.028-5.366-4.613-6.348-5.81zm10.113 0a4.491 4.491 0 014.483-4.486c.604 0 1.175.12 1.7.34l-5.844 5.848a4.395 4.395 0 01-.339-1.702z"
      ></path>
    </svg>
  );
}

export default ClosedEyeIcon;
