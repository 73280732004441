const es = {
  translations: {
    sideBar: {
      add: "Alta de cuentas y usuarios corporativos",
      modif: "Gestión de Clientes Corporativos",
      admin: "Gestión de Administradores de Plataforma",
      services: "Solicitudes de Servicio",
      inquiry: "Consulta de Solicitudes",
      printLabel: "Impresión de Etiquetas",
      tracking: "Seguimiento de Envíos",
      generalSettings: "Configuración de Parámetros Generales",
      userAdmin: "Administración de Usuarios",
    },
    printLabelScreen: {
      title: "Impresión de Etiquetas",
      ticket: "Nro de Pedido",
      tracking: "Guia",
      piece: "ID Pieza",
      search: "Buscar",
      print: "Imprimir Etiquetas",
    },
    modification: "Modificación",
    formatAdministration: "Administrador de Formatos",
    locationAdministration: "Administrador de Localidades",
    printLabelTable: {
      piece: "ID Pieza",
      name: "Nombre",
      address: "Dirección",
      cpa: "CPA",
      city: "Ciudad",
      province: "Provincia",
      idRequest: "ID Solicitud",
      shipping: "Envio",
      tracking: "Guía",
      state: "Estado",
    },
    test: "prueba",
    login: "Acceder",
    signin: "Registrarse",
    cancel: "Cancelar",
    logout: "Cerrar sesión",
    changeLanguage: "Cambiar lenguaje",
    loading: "Cargando...",
    toBack: "Atras",
    toForward: "Adelante",
    filters: "Filtros",
    type: "Tipo",
    deactivate: "Inactivar",
    search: "Buscar",
    passwordChange: "Cambio de Contraseña",
    register: "Alta",
    firstTimeLoggedAclaration:
      "Ha ingresado con una contraseña temporaria, por su seguridad deberá cambiarla por una nueva.",
    addUser: "Agregar usuario",
    titles: {
      regUserRoot: "Gestión de Administradores de Plataforma",
      welcome: "Bienvenido",
      adminAccAndUser: "administracion de cuentas y usuarios",
      registeAccountAndUser: "Alta de Cuentas y Usuarios Corporativos",
      userAdministration: "Administración de Usuarios",
      queryRequest: "Consulta de Solicitudes",
      serviceRequest: "Solicitud de Servicio",
    },
    recoveryEmailSent: "E-mail enviado. ¡Gracias!",
    emailSentAclaration:
      "Por favor, verifique su casilla de correo. En caso de no recibir su clave temporal, póngase en contacto con el administrador.",
    subTitles: {
      users: "Usuarios",
      roots: "Administradores",
      regUserRoot: "Alta Usuario Administrador",
    },
    loginScreen: {
      email: "Usuario / E-mail",
      password: "Contraseña",
      enter: "Acceder",
      warningPass: "Por favor ingrese contraseña",
      wrongEmailFormat: "Por favor ingresa un email correcto",
      enterYourEmail: "Por favor ingresa tu correo",
      enterYourPassword: "Por favor ingresar una contraseña",
      forgotPassword: "¿Olvidaste tu contraseña?",
      errorMessage: "Hubo un error al intentar ingresar",
      errorTitle:
        "No se pudo identificar Verifique el correo electrónico y contraseña ingresada.",
      errorHeader: "Error",
    },
    registerScreen: {
      firstName: "Nombre",
      lastName: "Apellido",
      password: "Ambas contraseñas deben ser iguales",
      enterYourFirstName: "Por favor ingresa tu nombre",
      enterYourLastName: "Por favor ingresa tu apellido",
      pricingExcelAclaration:
        "Para generar un nuevo tarifario, adjuntá tu planilla Excel.",
      noPricingError:
        "No se puede crear la cuenta sin un Tarifario asociado. Por favor cargue un tarifario",
    },
    rootModificationScreen: {
      mainTitle: "Gestión de Administradores de Plataforma",
      roots: "Administradores",
    },
    accountModificationScreen: {
      mainTitle: "Gestión de Clientes Corporativos",
      editTitle: "Edición",
      codeEco: "Código Interno ECO",
      tableDeactivateAlertMessage:
        "Se desactivarán los usuarios seleccionados. Los nombres de usuario y las transacciones efectuadas en la plataforma se guardarán con fines de auditoría",
      deactivateAlertMessage:
        "Si desactiva el usuario, los nombres y las transacciones efectuadas en la plataforma se guardarán con fines de auditoría.",
    },
    serviceRequests: {
      mainTitle: "Solicitudes de Servicio",
      requestsUploaded: "Solicitudes de Servicio Cargadas",
      requestsWithError: "Solicitudes con Error",
      warnings: "Advertencias",
      observations: "Observaciones",
      editRequests: "Editar solicitudes",
      editRequest: "Editar solicitud",
      deleteRequest: "Anular",
      requestId: "ID de Solicitud",
      recipient: "Destinatario",
      localidad: "Localidad",
      province: "Provincia",
      cpa: "CPA",
      docNumber: "Numero de Documento",
      excelDownloadAclaration:
        "Para generar tus solicitudes de servicio adjuntá tu archivo.",
      pieces: "Piezas",
      weight: "Peso",
      details: "Detalles",
      createRequests: "Crear solicitudes",
      editCancel: "Cancelar edición",
      deleteSelected: "Eliminar seleccionadas",
      requestsPreload: "Precarga de solicitudes",
      rowsHaveBeenDeleted: "La/s fila/s han sido eliminadas",
      date: "Fecha",
      order: "Pedido",
      name: "Nombre",
      city: "Ciudad",
      box: "Caja",
      guide: "Guia",
      send: "Envio",
      state: "Estado",
      actions: "Acciones",
      homeDelivery: "Entrega en domicilio",
      payUponReceipt: "Pago en destino",
      remittanceDone: "Remito conformado",
      requestDetail: "Detalle de solicitud",
      address: "Domicilio - Calle",
      addressNumber: "Domicilio - Número",
      addressBuild: "Domicilio - Edificio",
      addressFloor: "Domicilio - Piso",
      addressApartment: "Domicilio - Departamento",
      enabledPlace: "Lugares Habilitados",
    },
    serviceRequestsDetail: {
      requestId: "ID de Solicitud",
      recipientFullname: "Destinatario",
      voucher: "Guia",
      province: "Provincia",
      cpa: "CPA",
      address: "Domicilio - Calle",
      envio: "Envio",
      estado: "Estado",
      pieceId: "Id de Pieza",
      createdAt: "Fecha de creación",
      caja: "Caja",
      city: "Ciudad",
    },
    showMore: "Ver más",
    serviceRequestSummaryScreen: {
      infoText: "¡Se cargaron tus pedidos! Podés consultarlos desde ",
      queryServiceRequest: "Consulta de Solicitudes",
      requestCreated: "Solicitudes de Servicio Creadas",
      requestError: "Solicitudes con Error (No Creadas)",
      requestWarning: "Advertencias",
      seeDetail: "Ver detalle",
    },
    questionPreSave: "¿Desea guardar los cambios?",
    createSuccesAccounts: "Cuenta/s creada/s exitosamente!",
    createSuccesUsers: "Usuario/s creado/s exitosamente!",
    accountDeactivate: "Se desactivaron las cuentas",
    userDeactivate: "Se desactivaron el/los usuario/s",
    send: "Enviar",
    exit: "Salir",
    accounts: "Cuentas",
    filter: "Filtrar",
    save: "Guardar",
    saveChanges: "Guardar",
    saveChangesQuestion: "¿Desea guardar los cambios?",
    deleteQuestion: "¿Desea anular la solicitud?",
    operatorHelp: "Solicitar asistencia a operador",
    historyModification: "Historial Modificaciones",
    changesHaveBeenSaved: "Los cambios han sido guardados",
    deleteHaveBeenSaved: "Se ha eliminado la solicitud",
    users: "Usuarios",
    succesPassReset: "Se envió correo con clave temporal de ingreso",
    tables: {
      headersName: {
        user: "usuario",
        name: "nombre",
        lastName: "apellido",
        rol: "rol",
      },
    },
    formLabels: {
      company: "compañía",
      ecoCode: "código interno (ECO)",
      name: "nombre",
      lastName: "apellido",
      email: "Email",
      telephone: "Teléfono",
      docType: "Tipo de documento",
      docNumber: "Número de documento",
      availablePlaces: "Lugares habilitados",
      userName: "Usuario",
      role: "rol",
      placeHolder: {
        enter: "Ingrese ",
      },
      id: "ID",
      type: "Tipo",
      deactivate: "Inactivar",
      recoveryPassword: "Blanquear contraseña",
      userRol: "Rol del usuario",
      fromDate: "Desde el",
      toDate: "al",
      numberOrder: "N° Pedido",
      numberGuide: "Guia",
      idPiece: "ID Pieza",
      originPlaceId: "ID Lugar de origen",
      secureId: "ID Seguro",
      takeAwayId: "ID Retiro a Domicilio",
      homeDeliveryId: "ID Entrega a Domicilio",
      originAgencyId: "ID Agencia de Origen",
      voucherLetter: "Letra Comprobante",
      voucherPoint: "Boca Comprobante",
      originPlace: "Lugar de Origen",
      agencyName: "Nombre Agencia",
      agencyAddress: "Domicilio Agencia",
      agencyPhone: "Telefono Agencia",
      agencyZipCode: "Código Postal Agencia",
      otherAmounts: "Otros Importes",
      cuil: "CUIL",
      idClientEntity: "ID Cliente",
    },
    formValidationWarning: {
      fieldEmpty: "Campo requerido y no puede estar vacio",
    },
    formButtons: {
      editEnd: "Terminar edición",
      addUser: "Añadir Usuario",
      save: "Guardar",
      searchOrder: "Buscar Pedidos",
      exportCSV: "exportar consulta en excel (csv)",
      newServiceRequest: "Nueva Carga",
    },
    modalButtons: {
      accept: "Aceptar",
      cancel: "Cancelar",
      close: "Cerrar",
      continue: "Continuar",
      createUser: "Crear Usuarios",
      createAccount: "Crear Cuenta",
    },
    trackingScreen: {
      showActiveTickets: "Ver pedidos activos Pendientes",
      showOnRoadTickets: "Ver pedidos en Tránsito",
      showDeliveringTickets: "Ver pedidos en Entrega",
      deliveryPackageDateAclaration:
        "La fecha de entrega estimada de su pedido es:",
      delivering: "En reparto",
      proccessing: "Procesando",
      onRoad: "En camino a sucursal",
    },
    locationByZone: {
      mainTitle: "Redefinir CP´s por zona",
      subTitle:
        "Seleccione un Código Postal de la tabla y presione el botón Agregar para asignarlo a una zona.",
      previousPage: "ADMINISTRAR ZONAS Y TARIFAS",
      currentPage: "CP´S POR ZONA",
      tableTitle1: "Código Postal",
      tableTitle2: "provincia",
      tableTitle3: "Seleccionar",
      tableTitle4: "seleccionar todos",
      defaultOptionValue1: "Seleccione una provincia",
      defaultOptionValue2: "Seleccione un cp",
      actionButton1: "Guardar",
      actionButton2: "Volver",
      actionButton3: "Agregar",
      actionButton4: "Quitar",
    },
    confirmationQuestion: "¿Desea confirmar la operación?",
    thereAreNotElementsToShow: "No hay elementos para mostrar",
    home: "Home",
    requiredFieldError: "Este campo es obligatorio",
    edit: "Editar",
    action: "Acciones",
    downloadPricing: "Descargar tarifario",
    downloadLocation: "Descargar localidades",
  },
};

export default es;
