const API_CALL = 'user/API_CALL';
const GET_USER = 'user/GET_USER';
const GET_USERS = 'user/GET_USERS';
const GET_ROLES = 'user/GET_ROLES';
const UPDATE_ROLES = 'user/UPDATE_ROLES'
const GET_USER_PREFERENCES = 'user/GET_USER_PREFERENCES';
const CHANGE_CONFIGURATION = 'user/CHANGE_CONFIGURATION';
const CHANGE_CONFIGURATION_DO = 'user/CHANGE_CONFIGURATION_DO';
const UPDATE_USER = 'user/UPDATE_USER';
const UPDATE_USER_DO = 'user/UPDATE_USER_DO';
const RESET_STATE = 'user/RESET_STATE';
const RESET_STATE_DO = 'user/RESET_STATE';
const CREATE_USER = 'user/CREATE_USER';
const CREATE_CORPORATE_USER = 'user/CREATE_CORPORATE_USER';
const CREATE_USER_DO = 'user/CREATE_USER_DO';
const DELETE_USER = 'user/DELETE_USER';
const UPLOAD_FILE = 'user/UPLOAD_FILE';
const FETCH_USERS = 'user/FETCH_USERS';
const FETCH_ACCOUNTS = 'user/FETCH_ACCOUNTS';
const DEACTIVATE_ACCOUNTS = 'user/DEACTIVATE_ACCOUNTS';
const DEACTIVATE_USERS = "user/DEACTIVATE_USERS";
const UPDATE_USERS_LIST = "user/UPDATE_USERS_LIST";
const RECOVERY_USERS = "user/RECOVERY_USERS";
const UPDATE_ACCOUNT = "account/UPDATE_ACCOUNT";
const UPDATE_PRICING_FILE = "account/UPDATE_PRICING_FILE";
export default {
  API_CALL,
  GET_USER,
  GET_USERS,
  GET_USER_PREFERENCES,
  CHANGE_CONFIGURATION,
  CHANGE_CONFIGURATION_DO,
  UPDATE_USER,
  UPDATE_USER_DO,
  RESET_STATE,
  RESET_STATE_DO,
  GET_ROLES,
  UPDATE_ROLES,
  CREATE_USER,
  CREATE_CORPORATE_USER,
  CREATE_USER_DO,
  DELETE_USER,
  UPLOAD_FILE,
  FETCH_USERS,
  DEACTIVATE_ACCOUNTS,
  DEACTIVATE_USERS,
  FETCH_ACCOUNTS,
  UPDATE_USERS_LIST,
  RECOVERY_USERS,
  UPDATE_ACCOUNT,
  UPDATE_PRICING_FILE
}