import React from "react";

function IconAdd() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="28"
            fill="none"
            viewBox="0 0 35 28"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M5.326 6.808c0 3.755 3.074 6.814 6.848 6.814s6.848-3.053 6.848-6.808S15.948 0 12.174 0 5.326 3.053 5.326 6.808zm1.374 0c0-3.002 2.451-5.448 5.474-5.448s5.474 2.453 5.474 5.454-2.451 5.447-5.474 5.447S6.7 9.81 6.7 6.808zM23.67 28c.049 0 .097-.005.143-.015a9.114 9.114 0 01-4.247-1.328H1.351v-4.711c0-2.598 2.124-4.71 4.736-4.71h9.287c.087-.462.209-.91.363-1.344h-9.65C2.733 15.892 0 18.61 0 21.946v5.38c0 .371.304.674.678.674H23.67zM26 25.875a7.875 7.875 0 100-15.75 7.875 7.875 0 000 15.75zM26 27a9 9 0 100-18 9 9 0 000 18zm-.758-11.998c0-.344.281-.624.628-.624.346 0 .627.28.627.624v2.536h2.55c.346 0 .627.28.627.624 0 .345-.28.624-.627.624h-2.55v2.536c0 .345-.281.624-.627.624a.626.626 0 01-.628-.624v-2.536h-2.55a.626.626 0 01-.627-.624c0-.344.281-.624.627-.624h2.55v-2.536z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default IconAdd;
