import React from "react";

function EyeIcon({ width, height, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "34"}
      height={height ? height : "21"}
      onClick={onClick}
      fill="none"
      viewBox="0 0 34 21"
      className="clickeable"
    >
      <path
        fill="#fff"
        d="M33.78 9.86C33.481 9.454 26.243 0 17 0 7.756 0 .517 9.454.212 9.86c-.286.38-.286.9 0 1.28.305.406 7.544 9.86 16.78 9.86 9.238 0 16.483-9.46 16.782-9.86.298-.38.298-.9.006-1.28zM17 18.83c-6.807 0-12.704-6.275-14.45-8.328C4.29 8.443 10.172 2.175 17 2.175c6.806 0 12.703 6.275 14.45 8.328-1.74 2.053-7.63 8.328-14.45 8.328zm0-14.847c-3.706 0-6.727 2.922-6.727 6.52 0 3.597 3.021 6.512 6.727 6.512 3.705 0 6.726-2.922 6.726-6.52 0-3.597-3.021-6.512-6.726-6.512zm0 10.863c-2.47 0-4.483-1.95-4.483-4.344S14.529 6.16 17 6.16c2.47 0 4.482 1.95 4.482 4.344S19.47 14.847 17 14.847z"
      ></path>
    </svg>
  );
}

export default EyeIcon;
