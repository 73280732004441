const API_CALL = 'tracking/API_CALL';
const RESET_TRACKING = 'tracking/RESET_TRACING';
const RESET_TRACKING_DO = 'trackng/RESET_TRACING_DO';
const SET_SAID_TOKEN = 'tracking/SET_SAID';
const GET_SAID_TOKEN = 'tracking/GET_SAID';
const GET_DELIVERY_STATE = 'tracking/GET_DELIVERY_STATE';
const SET_DELIVERY_STATE = 'tracking/SET_DELIVERY_STATE';
const GET_ALL_DELIVERIES = 'tracking/GET_ALL_DELIVERIES';
export default {
    API_CALL,
    RESET_TRACKING,
    RESET_TRACKING_DO,
    SET_SAID_TOKEN,
    GET_SAID_TOKEN,
    SET_DELIVERY_STATE,
    GET_DELIVERY_STATE,
    GET_ALL_DELIVERIES
}