// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../resources/images/buspack-login1.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login button {\n  margin-top: 15px;\n}\n.login .img-login-container {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-color: transparent;\n  width: 720px;\n  max-height: 100%;\n  min-height: auto;\n  background-position: left;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n.login .form-container {\n  height: 85vh;\n}", "",{"version":3,"sources":["webpack://src/scenes/login/styles.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;AADJ;AAGE;EACE,yDAAA;EACA,6BAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,4BAAA;EACA,sBAAA;AADJ;AAGE;EACE,YAAA;AADJ","sourcesContent":[".login {\n\n  button {\n    margin-top: 15px;\n  }\n  .img-login-container{\n    background-image: url(\"../../../src/resources/images/buspack-login1.png\");\n    background-color: transparent;\n    width: 720px;\n    max-height: 100%;\n    min-height: auto;\n    background-position: left;\n    background-repeat: no-repeat;\n    background-size: cover;\n  }\n  .form-container{\n    height: 85vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
