import React from "react";

function IconQR() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="36"
            fill="none"
            viewBox="0 0 30 36"
        >
            <path
                fill="#fff"
                d="M13.873 9.16c0-.88-.72-1.6-1.599-1.6H9.277c-.88 0-1.6.72-1.6 1.6v2.996c0 .88.72 1.6 1.6 1.6h2.997c.88 0 1.6-.72 1.6-1.6V9.16zm-1.055 2.988a.543.543 0 01-.544.544H9.277a.543.543 0 01-.544-.544V9.151c0-.302.242-.544.544-.544h2.997c.301 0 .544.242.544.544v2.997zM18.478 9.837h-.57a.525.525 0 00-.527.528v.569c0 .293.234.527.527.527h.57a.525.525 0 00.527-.527v-.57a.53.53 0 00-.527-.527z"
            ></path>
            <path
                fill="#fff"
                d="M11.06 9.837h-.569a.525.525 0 00-.527.528v.569c0 .293.234.527.527.527h.57a.525.525 0 00.527-.527v-.57a.53.53 0 00-.527-.527zM9.277 21.165h2.997c.88 0 1.6-.72 1.6-1.6v-2.997c0-.879-.72-1.599-1.6-1.599H9.277c-.88 0-1.6.72-1.6 1.6v2.997c.01.879.72 1.599 1.6 1.599zm-.536-4.597c0-.301.243-.544.544-.544h2.997c.302 0 .545.243.545.544v2.998a.543.543 0 01-.545.544H9.285a.543.543 0 01-.544-.544v-2.998zM9.92 5.274H6.998c-.879 0-1.599.72-1.599 1.6v2.921c0 .293.235.528.528.528a.525.525 0 00.527-.528V6.873c0-.3.243-.544.544-.544H9.92a.525.525 0 00.528-.527.53.53 0 00-.528-.528z"
            ></path>
            <path
                fill="#fff"
                d="M10.491 18.88h.57a.525.525 0 00.527-.528v-.57a.525.525 0 00-.527-.527h-.57a.525.525 0 00-.527.527v.57c0 .293.234.527.527.527zM19.691 7.56h-2.997c-.88 0-1.6.72-1.6 1.6v2.996c0 .88.72 1.6 1.6 1.6h2.997c.88 0 1.6-.72 1.6-1.6V9.16a1.604 1.604 0 00-1.6-1.599zm.536 4.588a.543.543 0 01-.544.544h-2.998a.543.543 0 01-.544-.544V9.151c0-.302.243-.544.544-.544h2.998c.301 0 .544.242.544.544v2.997zM5.4 21.843c0 .879.72 1.599 1.598 1.599H9.92a.525.525 0 00.528-.528.525.525 0 00-.528-.527H6.998a.543.543 0 01-.544-.544V18.92a.525.525 0 00-.527-.528.525.525 0 00-.528.528v2.922zM19.047 23.442h2.922c.879 0 1.599-.72 1.599-1.6v-2.921a.525.525 0 00-.527-.528.525.525 0 00-.528.528v2.922a.543.543 0 01-.544.544h-2.922a.525.525 0 00-.527.527c0 .293.234.528.527.528z"
            ></path>
            <path
                fill="#fff"
                d="M23.568 6.873c0-.879-.72-1.599-1.6-1.599h-2.921a.525.525 0 00-.527.528c0 .293.234.527.527.527h2.922c.301 0 .544.243.544.544v2.922c0 .293.234.528.527.528a.525.525 0 00.528-.528V6.873zM15.095 18c0 .486.393.887.887.887h1.884c.05 0 .084.034.084.084v1.315c0 .485.393.887.887.887h1.566a.888.888 0 00.887-.887V18.72a.888.888 0 00-.887-.887h-1.315c-.05 0-.084-.034-.084-.084v-1.884a.888.888 0 00-.887-.887h-2.135a.888.888 0 00-.887.887V18zm3.91.963c0-.05.033-.084.083-.084h1.055c.05 0 .084.034.084.084v1.055c0 .05-.034.083-.084.083h-1.055c-.05 0-.084-.033-.084-.083v-1.055zm-2.855-2.847c0-.05.033-.084.083-.084h1.625c.05 0 .083.034.083.084v1.625c0 .05-.033.083-.083.083h-1.625c-.05 0-.083-.033-.083-.084v-1.624z"
            ></path>
            <path
                fill="#fff"
                d="M28.716 0H.586A.582.582 0 000 .586v34.828c0 .326.26.586.586.586H19.34c.008 0 .025-.008.041-.008.05 0 .1-.017.143-.034l.025-.008c.008 0 .025-.009.033-.009a.498.498 0 00.176-.117l9.377-9.377a.553.553 0 00.117-.167c.009-.008.009-.025.009-.034l.008-.025c.017-.05.025-.1.033-.15 0-.017 0-.025.009-.042V.578A.593.593 0 0028.716 0zM27.16 26.766l-7.091 7.09a.076.076 0 01-.059.026c-.008 0-.025 0-.033-.008-.034-.017-.05-.042-.05-.076v-7.091c0-.05.033-.084.083-.084H27.1c.034 0 .067.017.076.05.017.034.008.068-.017.093zm.971-1.399c0 .05-.033.084-.083.084H19.34a.582.582 0 00-.587.586v8.707c0 .05-.033.084-.083.084H1.256c-.05 0-.084-.034-.084-.084V1.256c0-.05.034-.084.084-.084h26.79c.05 0 .084.034.084.084v24.111z"
            ></path>
        </svg>
    );
}

export default IconQR;