import React from "react";

function IconDisplayInfo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="32"
            fill="none"
            viewBox="0 0 35 32"
        >
            <path
                fill="#fff"
                d="M19.155 14.393a.07.07 0 01-.051-.067v-1.393c0-.03.022-.059.051-.066a1.69 1.69 0 00.955-.874c.197-.415.219-.875.066-1.304a1.663 1.663 0 00-1.021-1.037.071.071 0 01-.051-.067V8.193c0-.03.022-.06.051-.067.423-.148.758-.46.955-.874.19-.408.219-.874.066-1.304a1.673 1.673 0 00-2.144-1.037c-.875.311-1.327 1.289-1.02 2.178.167.489.539.867 1.02 1.037.03.007.051.037.051.067v1.392c0 .03-.022.06-.05.067a1.69 1.69 0 00-.956.874c-.19.407-.219.874-.066 1.304.168.489.54.866 1.021 1.037a.07.07 0 01.051.066v1.393c0 .03-.022.06-.05.067A1.7 1.7 0 0016.915 16c0 .94.752 1.704 1.677 1.704.927 0 1.678-.763 1.678-1.704 0-.719-.453-1.363-1.116-1.607zm-1.218-7.874c0-.37.292-.667.656-.667.365 0 .657.296.657.667 0 .37-.292.666-.657.666a.659.659 0 01-.656-.666zm0 4.74c0-.37.292-.666.656-.666.365 0 .657.296.657.666 0 .37-.292.667-.657.667a.659.659 0 01-.656-.667zm.656 5.408a.659.659 0 01-.656-.667c0-.37.292-.667.656-.667.365 0 .657.297.657.667 0 .37-.292.667-.657.667zM27.489 5.926h-5.396a.59.59 0 00-.583.593.59.59 0 00.583.592h5.396a.59.59 0 00.583-.592.59.59 0 00-.583-.593zM30.406 5.926h-.729a.59.59 0 00-.583.593.59.59 0 00.583.592h.73a.59.59 0 00.583-.592.59.59 0 00-.584-.593zM27.489 10.667h-5.396a.59.59 0 00-.583.592.59.59 0 00.583.593h5.396a.59.59 0 00.583-.593.59.59 0 00-.583-.592zM30.406 10.667h-.729a.59.59 0 00-.583.592.59.59 0 00.583.593h.73a.59.59 0 00.583-.593.59.59 0 00-.584-.592zM27.489 15.408h-5.396a.59.59 0 00-.583.592.59.59 0 00.583.593h5.396a.59.59 0 00.583-.593.59.59 0 00-.583-.592zM30.406 15.408h-.729a.59.59 0 00-.583.592.59.59 0 00.583.593h.73A.59.59 0 0030.99 16a.59.59 0 00-.584-.592z"
            ></path>
            <path
                fill="#fff"
                d="M32.083 0H2.917C1.305 0 0 1.326 0 2.963v20.148c0 1.637 1.305 2.963 2.917 2.963h9.202L11.09 31.29a.59.59 0 00.459.696c.036.008.073.015.117.015h11.666a.59.59 0 00.57-.711l-1.022-5.215h9.202c1.612 0 2.917-1.326 2.917-2.963V2.963C35 1.326 33.695 0 32.083 0zM12.381 30.815l.934-4.74h8.378l.933 4.74H12.381zm21.452-7.704c0 .978-.787 1.778-1.75 1.778H2.917c-.963 0-1.75-.8-1.75-1.778v-1.778h32.666v1.778zm0-2.963H1.167V2.963c0-.978.787-1.778 1.75-1.778h29.166c.963 0 1.75.8 1.75 1.778v17.185z"
            ></path>
            <path
                fill="#fff"
                d="M4.083 3.556a.588.588 0 00.584-.593.588.588 0 00-.584-.593.588.588 0 00-.583.593c0 .327.261.593.583.593zM6.417 3.556a.588.588 0 00.584-.593.588.588 0 00-.584-.593.588.588 0 00-.583.593c0 .327.261.593.583.593zM8.75 3.556a.588.588 0 00.584-.593.588.588 0 00-.584-.593.588.588 0 00-.583.593c0 .327.261.593.583.593zM16.916 23.704h1.167a.59.59 0 00.583-.593.59.59 0 00-.583-.592h-1.167a.59.59 0 00-.583.592.59.59 0 00.583.593zM14.387 7.867l-5.06-2.97a.472.472 0 00-.482 0l-5.06 2.97a.49.49 0 00-.241.43v5.933c0 .177.095.34.24.43l5.061 2.97a.48.48 0 00.489 0l5.06-2.97a.49.49 0 00.24-.43V8.296a.503.503 0 00-.247-.43zm-6.803-1.09l1.48-.866c.014-.007.022-.007.036 0l4.01 2.356c.015.007.015.022.015.03a.057.057 0 01-.014.029l-1.48.867c-.008 0-.015.007-.015.007s-.015 0-.015-.007l-4.01-2.356c-.015-.007-.015-.022-.015-.03 0-.007-.007-.022.008-.03zm1.013 9.497c0 .015-.007.022-.015.03-.007 0-.014.007-.014.007s-.015 0-.015-.007l-4.01-2.356c-.015-.007-.015-.022-.015-.03v-4.71c0-.015.008-.023.015-.03.014-.008.022-.008.036 0l4.01 2.355c.015.008.015.023.015.03v4.711h-.007zm.503-5.6c-.007 0-.014.007-.014.007s-.015 0-.015-.007l-4.01-2.356c-.015-.007-.015-.022-.015-.03 0-.007.007-.021.015-.029l1.48-.866c.014-.008.022-.008.036 0l4.01 2.355c.015.008.015.022.015.03a.056.056 0 01-.014.03l-1.488.866zm4.558 3.245c0 .014-.008.022-.015.03l-4.01 2.355c-.008 0-.015.007-.015.007s-.015 0-.015-.007c-.014-.008-.014-.023-.014-.03v-4.711c0-.015.007-.022.014-.03l4.01-2.355c.015-.008.023-.008.037 0 .015.007.015.022.015.03v4.71h-.007z"
            ></path>
            <path
                fill="#fff"
                d="M13.023 11.4a.473.473 0 00-.481 0l-2.334 1.37a.49.49 0 00-.24.43v1.6c0 .178.095.34.24.43a.48.48 0 00.489 0l2.333-1.37a.49.49 0 00.24-.43v-1.6a.513.513 0 00-.247-.43zm-.73 1.719c0 .014-.006.022-.014.03l-1.283.755c-.007 0-.015.007-.015.007s-.014 0-.014-.007c-.015-.008-.015-.022-.015-.03v-.37c0-.015.007-.022.015-.03l1.283-.755c.015-.008.022-.008.037 0 .014.007.014.022.014.03v.37h-.007z"
            ></path>
        </svg>
    );
}

export default IconDisplayInfo;