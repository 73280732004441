import React from "react";


function AlertIcon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "20"}
      height={height ? height : "20"}
      fill="none"
      viewBox="0 0 25 25"
    >
      <path fill="#F28E2A" d="M13.4 18.4a1 1 0 11-2 0 1 1 0 012 0z"></path>
      <path
        fill="#F28E2A"
        fillRule="evenodd"
        d="M12.4 17.8a.6.6 0 100 1.2.6.6 0 000-1.2zm-1.4.6a1.4 1.4 0 112.8 0 1.4 1.4 0 01-2.8 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F28E2A"
        d="M12.4 15.4a.75.75 0 01-.75-.75v-8.5a.75.75 0 011.5 0v8.5a.75.75 0 01-.75.75z"
      ></path>
      <path
        fill="#F28E2A"
        fillRule="evenodd"
        d="M12.4 5.8a.35.35 0 00-.35.35v8.5a.35.35 0 00.7 0v-8.5a.35.35 0 00-.35-.35zm-1.15.35a1.15 1.15 0 012.3 0v8.5a1.15 1.15 0 01-2.3 0v-8.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F28E2A"
        d="M21.65 24.4H3.15A2.752 2.752 0 01.4 21.65V3.15A2.752 2.752 0 013.15.4h18.5a2.752 2.752 0 012.75 2.75v18.5a2.752 2.752 0 01-2.75 2.75zM3.15 1.9c-.69 0-1.25.561-1.25 1.25v18.5c0 .689.56 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25V3.15c0-.689-.561-1.25-1.25-1.25H3.15z"
      ></path>
      <path
        fill="#F28E2A"
        fillRule="evenodd"
        d="M3.15.8A2.352 2.352 0 00.8 3.15v18.5A2.352 2.352 0 003.15 24h18.5A2.352 2.352 0 0024 21.65V3.15A2.352 2.352 0 0021.65.8H3.15zm18.5 24H3.15A3.152 3.152 0 010 21.65V3.15A3.152 3.152 0 013.15 0h18.5a3.152 3.152 0 013.15 3.15v18.5a3.152 3.152 0 01-3.15 3.15zM1.5 3.15c0-.91.74-1.65 1.65-1.65h18.5c.91 0 1.65.74 1.65 1.65v18.5c0 .91-.74 1.65-1.65 1.65H3.15c-.91 0-1.65-.74-1.65-1.65V3.15zm1.65-.85a.852.852 0 00-.85.85v18.5c0 .468.382.85.85.85h18.5c.468 0 .85-.382.85-.85V3.15a.852.852 0 00-.85-.85H3.15z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default AlertIcon;
