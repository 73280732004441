import React from "react";

function IconModif() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="28"
            fill="none"
            viewBox="0 0 36 28"
        >
            <g fill="#fff" clipPath="url(#clip0)">
                <path d="M35.313 9.163l-1.21-1.232a2.326 2.326 0 00-3.332 0L17.202 21.75c-.006 0-.006.006-.012.006a.108.108 0 00-.025.032l-.006.006c-.012.012-.018.018-.024.031a.561.561 0 00-.11.238l-1.21 4.93a.657.657 0 00.626.813.608.608 0 00.154-.018l4.817-1.226a.544.544 0 00.141-.05h.006l.037-.018c.025-.013.043-.032.068-.044 0 0 .006 0 .006-.006.012-.006.018-.019.03-.025.007 0 .007-.006.013-.006.006-.007.018-.013.024-.026l.007-.006 11.464-11.675.018-.018.019-.02 2.074-2.112a2.436 2.436 0 00-.006-3.393zM21.277 25l-2.725-2.775 10.58-10.775 2.726 2.775L21.278 25zm-3.283-1.5l2.031 2.069-2.7.687.669-2.756zm16.41-11.869L32.772 13.3l-2.725-2.775 1.639-1.669a1.06 1.06 0 011.516 0l1.209 1.232a1.114 1.114 0 01-.007 1.543zM11.783 13.331c-3.608 0-6.548-2.993-6.548-6.668C5.235 2.986 8.175 0 11.783 0c3.609 0 6.548 2.994 6.548 6.669 0 3.675-2.94 6.662-6.548 6.662zm0-12c-2.89 0-5.235 2.394-5.235 5.332C6.548 9.6 8.893 12 11.783 12s5.235-2.394 5.235-5.331c0-2.938-2.344-5.338-5.235-5.338z"></path>
                <path d="M15.367 27.188l.117-.476v-.043H1.307V22c0-2.575 2.056-4.669 4.585-4.669h11.783c.877 0 1.694.25 2.393.688l.945-.963a5.78 5.78 0 00-3.338-1.062H5.892c-3.247 0-5.892 2.694-5.892 6v5.331c0 .369.295.669.657.669H15.79a1.809 1.809 0 01-.252-.194.64.64 0 01-.172-.613z"></path>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H36V28H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconModif;
