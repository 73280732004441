const API_CALL = 'auth/API_CALL';
const CLEAR = 'auth/CLEAR';
const END_FETCH = 'auth/END_FETCH';
const LOGIN = 'auth/LOGIN';
const LOGOUT = 'auth/LOGOUT';
const LOGGED = 'auth/LOGGED'
const REGISTER = 'auth/REGISTER';
const SIGN_IN = 'auth/SIGN_IN';
const START_FETCH = 'auth/START_FETCH';
const ADD_VALUE = 'auth/ADD_VALUE';
const SET_LENGUAGE = 'auth/SET_LENGUAGE';
const CHANGE_PASSWORD = "auth/CHANGE_PASSWORD";
const RECOVERY_PASS = 'auth/RECOVERY_PASS';
const ADD_FAILED_LOGIN = 'auth/ADD_FAILED_LOGIN';
const BLOCK_ACCOUNT = 'auth/BLOCK_ACCOUNT';
const BLOCK_ACCOUNT_DO = 'auth/BLOCK_ACCOUNT_DO';
const GET_ACCOUNT_STATUS = 'auth/GET_ACCOUNT_STATUS';
const RESET_ACCOUNTS = 'auth/RESET_ACCOUNTS';
const GET_HEADERS = 'auth/GET_HEADERS';

export default {
  API_CALL,
  CLEAR,
  END_FETCH,
  LOGIN,
  LOGOUT,
  LOGGED,
  REGISTER,
  SIGN_IN,
  START_FETCH,
  ADD_VALUE,
  SET_LENGUAGE,
  CHANGE_PASSWORD,
  RECOVERY_PASS,
  ADD_FAILED_LOGIN,
  BLOCK_ACCOUNT,
  BLOCK_ACCOUNT_DO,
  GET_ACCOUNT_STATUS,
  RESET_ACCOUNTS,
  GET_HEADERS
};