import React from "react";

function MarkerService() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="38"
            fill="none"
            viewBox="0 0 29 38"
        >
            <g fill="#fff" clipPath="url(#clip0)">
                <path d="M14.5 0C6.503 0 0 6.524 0 14.547c0 3.117.97 6.086 2.796 8.594l10.358 14.184a1.656 1.656 0 002.678 0c.037-.045 10.364-14.176 10.364-14.176A14.494 14.494 0 0029 14.547C29 6.524 22.497 0 14.5 0zm10.808 22.48c-.103.142-10.201 13.969-10.357 14.177v.007a.534.534 0 01-.444.23.557.557 0 01-.45-.23A161862.2 161862.2 0 013.698 22.488v-.007a13.338 13.338 0 01-2.59-7.934C1.11 7.14 7.118 1.113 14.5 1.113S27.89 7.14 27.89 14.547c0 2.872-.895 5.618-2.582 7.934z"></path>
                <path d="M7.036 10.598v8.098c0 .17.089.326.237.408l6.99 4.052a.457.457 0 00.474 0l6.992-4.052a.462.462 0 00.236-.408v-8.098a.462.462 0 00-.236-.408l-6.992-4.052a.488.488 0 00-.473 0L7.273 10.19a.462.462 0 00-.237.408zm.947.817l6.052 3.503v7.006l-6.052-3.503v-7.006zm6.518 2.687l-6.052-3.504 2.552-1.477 6.052 3.504-2.552 1.477zm.473 7.83v-7.014l6.052-3.503v7.006l-6.052 3.51zm-.473-14.837l6.051 3.503L18 12.075l-6.052-3.503 2.553-1.477z"></path>
                <path d="M19.842 15.014a.488.488 0 00-.473 0l-3.226 1.863a.462.462 0 00-.237.409v2.182c0 .17.089.326.237.408a.458.458 0 00.473 0l3.226-1.863a.462.462 0 00.237-.408v-2.182a.462.462 0 00-.237-.409zm-.703 2.316l-2.278 1.321v-1.09l2.278-1.322v1.091z"></path>
                <path d="M14.5 3.258a11.35 11.35 0 00-7.235 2.613.511.511 0 00-.066.727.507.507 0 00.725.067A10.326 10.326 0 0114.5 4.29c5.697 0 10.328 4.646 10.328 10.36 0 5.716-4.631 10.362-10.328 10.362-5.696 0-10.327-4.646-10.327-10.361A10.4 10.4 0 016.54 8.053a.525.525 0 00-.066-.728.521.521 0 00-.725.067 11.418 11.418 0 00-2.604 7.259c0 6.279 5.097 11.392 11.355 11.392 6.26 0 11.356-5.113 11.356-11.392 0-6.28-5.097-11.393-11.356-11.393z"></path>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H29V38H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MarkerService;
