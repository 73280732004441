import React from "react";

function IconDeliveryTruck() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="29"
            fill="none"
            viewBox="0 0 37 29"
        >
            <path
                fill="#fff"
                d="M36.823 15.942L29.16 8.117a1.797 1.797 0 00-1.31-.552h-9.968a.625.625 0 00-.616.63c0 .347.277.631.616.631h2.467v8.826c0 .347.277.63.617.63.339 0 .616-.283.616-.63V8.826h6.275c.162 0 .324.063.44.181l1.055 1.08h-2.836c-.679 0-1.234.567-1.234 1.26v5.675c0 .693.555 1.26 1.234 1.26h9.25v5.044c0 .347-.278.63-.617.63h-1.28c-.339-2.41-2.52-4.081-4.879-3.735-1.896.276-3.384 1.797-3.654 3.736h-8.733c-.34-2.412-2.52-4.082-4.88-3.736-1.896.276-3.384 1.797-3.653 3.736H6.783a.626.626 0 01-.616-.63v-5.044a.626.626 0 00-.617-.63.626.626 0 00-.617.63v5.043c0 1.048.825 1.892 1.85 1.892h1.28a4.445 4.445 0 001.249 2.521H.617a.625.625 0 00-.617.63c0 .347.278.631.617.631H29.6c2.143-.008 3.962-1.615 4.27-3.782h1.28c1.025 0 1.85-.844 1.85-1.892v-6.935a.646.646 0 00-.177-.449zM9.25 24.587c0-1.742 1.38-3.152 3.083-3.152 1.704 0 3.084 1.41 3.084 3.152 0 1.742-1.38 3.152-3.084 3.152-1.703 0-3.083-1.41-3.083-3.152zm6.097 3.152a4.398 4.398 0 001.249-2.521h8.734a4.445 4.445 0 001.248 2.521h-11.23zm14.253 0c-1.704 0-3.083-1.41-3.083-3.152 0-1.742 1.38-3.152 3.083-3.152 1.703 0 3.083 1.41 3.083 3.152 0 1.742-1.38 3.152-3.083 3.152zm-3.083-10.717v-5.674h4.062l5.188 5.303v.37h-9.25z"
            ></path>
            <path
                fill="#fff"
                d="M12.334 23.956c.339 0 .616.284.616.631 0 .347-.277.63-.616.63a.625.625 0 01-.617-.63c0-.347.277-.63.617-.63zM29.6 23.956c.34 0 .617.284.617.631 0 .347-.278.63-.617.63a.625.625 0 01-.617-.63c0-.347.278-.63.617-.63zM.617 22.065h1.85c.339 0 .616.284.616.63 0 .347-.277.631-.616.631H.617a.626.626 0 01-.617-.63c0-.347.278-.63.617-.63zM1.85 19.544h.617c.339 0 .616.283.616.63 0 .347-.277.63-.616.63H1.85a.625.625 0 01-.617-.63c0-.347.278-.63.617-.63zM2.466 17.022c.34 0 .617.283.617.63 0 .347-.278.63-.617.63a.625.625 0 01-.616-.63c0-.347.277-.63.616-.63zM8.017 16.391c4.424 0 8.016-3.672 8.016-8.195C16.033 3.672 12.441 0 8.017 0 3.592 0 0 3.672 0 8.196c.008 4.523 3.592 8.187 8.017 8.195zm0-15.13c3.746 0 6.783 3.105 6.783 6.935 0 3.83-3.037 6.934-6.783 6.934-3.747 0-6.784-3.105-6.784-6.934.008-3.83 3.037-6.927 6.784-6.935z"
            ></path>
            <path
                fill="#fff"
                d="M8.017 13.87a.617.617 0 00.44-.182c.4-.41 3.877-4.042 3.877-6.753 0-2.435-1.935-4.413-4.317-4.413S3.7 4.5 3.7 6.935c0 2.703 3.484 6.343 3.877 6.753a.617.617 0 00.44.182zm0-10.087c1.703 0 3.083 1.41 3.083 3.152 0 1.615-1.942 4.113-3.083 5.39-1.141-1.277-3.083-3.775-3.083-5.39 0-1.742 1.38-3.152 3.083-3.152z"
            ></path>
            <path
                fill="#fff"
                d="M9.867 6.935c0-1.048-.825-1.892-1.85-1.892-1.025 0-1.85.844-1.85 1.892s.825 1.891 1.85 1.891c1.025 0 1.85-.843 1.85-1.891zm-2.467 0c0-.347.278-.63.617-.63.34 0 .617.283.617.63 0 .347-.278.63-.617.63a.626.626 0 01-.617-.63zM24.05 19.544h1.234c.339 0 .616.283.616.63 0 .347-.277.63-.616.63H24.05a.625.625 0 01-.616-.63c0-.347.277-.63.616-.63z"
            ></path>
        </svg>
    );
}

export default IconDeliveryTruck;